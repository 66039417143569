import React from 'react';
import { graphql } from 'gatsby';
import { StructuredText } from 'react-datocms';
import Layout from '../../components/layout';
import { motion } from 'framer-motion';

const transition_short = { duration: 0.4, ease: [0.6, 0.01, -0.05, 0.9] };
const transition_long = { duration: 0.6, ease: [0.6, 0.01, -0.05, 0.9] };

export default function Artikkel({ data: { post } }) {
	return (
		<Layout pageTitle={post.title} background={true}>
			<motion.div
				className="hero"
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				exit={{ opacity: 0 }}
				transition={transition_long}
			>
				<div className="container">
					<h5>Insights —</h5>
					<h1>{post.title}</h1>
				</div>

				<div className="image">
					<img src={`${post.thumbnail.url}`} alt="" />
				</div>
			</motion.div>

			<div className="container">
				<div className="row">
					<motion.div
						className="col"
						initial={{ opacity: 0, y: 50 }}
						animate={{ opacity: 1, y: 0 }}
						exit={{ opacity: 0 }}
						transition={transition_long}
					>
						<div className="text-block">
							<h2>{post.excerpt}</h2>
							<StructuredText data={post.innhold} />

							{post.images?.map(({ url }) => (
								<div className="image">
									<img src={url}></img>
								</div>
							))}
						</div>
					</motion.div>
				</div>
			</div>

			<style jsx>{`
				.row {
					display: flex;
					align-items: center;
					justify-content: center;
					padding: 1.25rem 0;
					margin: 0 -1.25rem;
				}
				@media (max-width: 767px) {
					.row {
						flex-wrap: wrap;
						padding: 0 0 1.25rem;
					}
				}
				.row .col {
					display: flex;
					align-items: center;
					justify-content: center;
					padding: 0 1.25rem;
				}
				.hero .image img {
					width: 100%;
					max-width: none;
					height: auto;
				}
				h2 {
					font-weight: 400;
					letter-spacing: -1px;
					line-height: 1.16;
					padding-bottom: 0.9375rem;
				}

				@media (max-width: 991px) {
					h2 {
						font-size: 2.5rem;
					}
				}

				h4 {
					font-weight: 400;
					letter-spacing: -1px;
					line-height: 1.33;
				}

				h6 {
					line-height: 1.5;
				}

				h6,
				p {
					font-weight: 400;
					letter-spacing: -0.5px;
				}

				p {
					font-size: 1.25rem;
					line-height: 1.4;
				}

				img,
				picture {
					position: relative;
					display: block;
					width: auto;
					max-width: 100%;
					margin: 0 auto;
				}

				@media (max-width: 767px) {
					img.sm-full,
					picture.sm-full {
						margin-left: -20px;
						width: 100vw;
						max-width: none;
					}

					img.sm-full img,
					picture.sm-full img {
						margin-left: 0;
					}
				}

				img > span,
				picture > span {
					position: absolute;
					top: 1.875rem;
					left: 1.875rem;
					font-size: 1rem;
					font-weight: 600;
				}

				@media (max-width: 767px) {
					img > span,
					picture > span {
						top: 0.9375rem;
						left: 0.9375rem;
						font-size: 0.825rem;
					}
				}

				img > span.txt-white,
				picture > span.txt-white {
					color: #fff;
				}

				img > span.txt-white-light,
				picture > span.txt-white-light {
					color: hsla(0, 0%, 100%, 0.4);
				}

				img > span.txt-light,
				picture > span.txt-light {
					color: rgba(4, 40, 37, 0.33);
				}

				img > span.bottom,
				picture > span.bottom {
					top: auto;
					bottom: 1.875rem;
				}

				@media (max-width: 767px) {
					img > span.bottom,
					picture > span.bottom {
						top: auto;
						bottom: 0.9375rem;
					}
				}

				.container::not(.ng-tns-c64-0) {
					max-width: 1280px !important;
				}

				.intro__info {
					position: absolute;
					top: 0;
					left: 0;
					width: 33.33%;
					max-width: calc(320px * var(--scale));
					padding-right: calc(40px * var(--scale));
					font-size: 1rem;
					font-weight: 400;
					letter-spacing: -0.5px;
					line-height: 1.5;
				}

				@media (max-width: 767px) {
					.intro__info {
						position: relative;
						width: 100%;
						max-width: 100%;
						padding-right: 0;
						padding-bottom: calc(150px * var(--scale));
						margin-bottom: calc(150px * var(--scale));
						font-size: 1.25rem;
						line-height: 1.4;
					}

					.intro__info:after {
						content: '';
						position: absolute;
						bottom: 0;
						left: 0;
						right: 0;
						height: 1px;
						background-color: #dee2e6;
					}
				}

				.intro__info h6 {
					font-weight: 700;
					margin-bottom: calc(5px * var(--scale));
				}

				@media (max-width: 767px) {
					.intro__info h6 {
						font-weight: 400;
						line-height: 1.16;
						letter-spacing: -1px;
						margin-bottom: calc(15px * var(--scale));
					}
				}

				.intro__info h6:not(:first-child) {
					margin-top: calc(20px * var(--scale));
				}

				@media (max-width: 767px) {
					.intro__info h6:not(:first-child) {
						margin-top: calc(60px * var(--scale));
					}
				}

				.intro__info p {
					padding: 0 !important;
					font-size: 1rem;
					font-weight: 400;
					letter-spacing: -0.5px;
					line-height: 1.5;
				}

				@media (max-width: 767px) {
					.intro__info p {
						font-size: 1.25rem;
						line-height: 1.4;
					}
				}

				.intro__info a {
					margin-top: calc(15px * var(--scale));
				}

				.intro__info ul {
					margin: 0;
					padding: 0;
					list-style: none;
				}

				.intro__info ul li:before {
					content: '- ';
				}

				.project-result {
					overflow: hidden;
				}

				.project-result .container {
					position: relative;
					padding-left: calc(425px * var(--scale));
					padding-top: calc(150px * var(--scale));
					padding-bottom: calc(150px * var(--scale));
				}

				@media (min-width: 1500px) {
					.project-result .container {
						padding-left: calc(250px * var(--scale));
					}
				}

				@media (min-width: 992px) and(max-width: 1199px) {
					.project-result .container {
						padding-left: calc(256px * var(--scale));
					}
				}

				@media (max-width: 991px) {
					.project-result .container {
						padding-left: 20px;
						max-width: 600px;
						margin: 0 auto;
					}
				}

				.project-result .container > p {
					font-size: 1.5rem;
					letter-spacing: -0.5px;
					line-height: 1.33;
				}

				.project-result .container .quote {
					margin-top: calc(50px * var(--scale));
				}

				.project-result .container .quote .user {
					margin-top: calc(15px * var(--scale));
					font-size: 1rem;
					letter-spacing: -0.5px;
				}

				.project-result .container .visit {
					display: flex;
					align-items: center;
					margin-top: calc(50px * var(--scale));
				}

				.project-result .container .visit a {
					font-weight: 400;
					font-size: 1.5rem;
					letter-spacing: -1px;
					line-height: 1.33;
				}

				.project-result .container .visit img {
					display: block;
					margin-left: 0.625rem !important;
					margin-top: 3px !important;
				}

				.project-result .container .stats {
					margin-top: calc(100px * var(--scale));
					display: flex;
					flex-wrap: wrap;
				}

				.project-result .container .stats > div {
					flex: 0 0 50%;
					padding-left: calc(20px * var(--scale));
					padding-right: calc(20px * var(--scale));
					margin-top: calc(50px * var(--scale));
				}

				.project-result .container .stats > div:first-child,
				.project-result .container .stats > div:nth-child(2) {
					margin-top: 0;
				}

				@media (max-width: 767px) {
					.project-result .container .stats > div:nth-child(2) {
						margin-top: calc(50px * var(--scale));
					}
				}

				.project-result .container .stats > div:nth-child(odd) {
					padding-left: 0;
				}

				.project-result .container .stats > div:nth-child(2n) {
					padding-right: 0;
				}

				@media (max-width: 767px) {
					.project-result .container .stats > div {
						flex: 0 0 100%;
						padding-left: 0 !important;
						padding-right: 0 !important;
					}
				}

				.project-result .container .work-done {
					margin-top: calc(100px * var(--scale));
					display: flex;
					flex-wrap: wrap;
				}

				.project-result .container .work-done > div {
					flex: 0 0 50%;
					padding-left: calc(20px * var(--scale));
					padding-right: calc(20px * var(--scale));
					margin-top: calc(50px * var(--scale));
				}

				.project-result .container .work-done > div:first-child,
				.project-result .container .work-done > div:nth-child(2) {
					margin-top: 0;
				}

				@media (max-width: 767px) {
					.project-result .container .work-done > div:nth-child(2) {
						margin-top: calc(50px * var(--scale));
					}
				}

				.project-result .container .work-done > div:nth-child(odd) {
					padding-left: 0;
				}

				.project-result .container .work-done > div:nth-child(2n) {
					padding-right: 0;
				}

				@media (max-width: 767px) {
					.project-result .container .work-done > div {
						flex: 0 0 100%;
						padding-left: 0 !important;
						padding-right: 0 !important;
					}
				}

				.project-result .container .work-done > div h4 {
					font-size: 1.25rem;
					font-weight: 600;
					letter-spacing: -0.5px;
					line-height: 2;
				}

				.project-result .container .work-done > div ul {
					margin: 0;
					padding: 0;
					list-style: none;
				}

				.project-result .container .work-done > div ul li {
					position: relative;
					font-weight: 400;
					font-size: 1.25rem;
					letter-spacing: -0.5px;
					line-height: 2;
				}

				.project-result .container .work-done > div.credits {
					display: none;
				}

				.project-result .container .press {
					margin-top: calc(150px * var(--scale));
				}

				.project-result .container .press > div {
					text-align: center;
					margin-top: calc(100px * var(--scale));
				}

				.project-result .container .press > div .text {
					padding: calc(40px * var(--scale)) 0
						calc(20px * var(--scale)) 0;
					font-size: 2rem;
					font-weight: 400;
					letter-spacing: -1px;
					line-height: 1.25;
				}

				@media (max-width: 767px) {
					.project-result .container .press > div .text {
						font-size: 1.5rem;
					}
				}

				.project-result .container .press > div .name {
					font-size: 1rem;
					letter-spacing: -0.5px;
				}

				.project-result.w-820 .container,
				.testimonial .container {
					padding-left: 20px !important;
					padding-right: 20px !important;
					max-width: calc(860px * var(--scale)) !important;
					margin: 0 auto !important;
				}

				.testimonial .container {
					padding-top: calc(160px * var(--scale));
				}

				.testimonial .container > h2,
				.testimonial .container > h3 {
					letter-spacing: -1px;
					line-height: 1.17;
				}

				@media (max-width: 767px) {
					.testimonial .container > h2 {
						font-size: 2.375rem;
						line-height: 1.25;
					}
				}

				@media (max-width: 767px) {
					.testimonial .container > h3 {
						font-size: 1.625rem;
						line-height: 1.25;
					}
				}

				.testimonial .container .user {
					display: flex;
					align-items: center;
					padding-top: calc(40px * var(--scale));
				}

				.testimonial .container .user .image {
					height: 100px;
					width: 100px;
					min-width: 100px;
					max-width: 100px;
				}

				.testimonial .container .user .text {
					padding-left: calc(20px * var(--scale));
					max-width: calc(280px * var(--scale));
				}

				@media (max-width: 1199px) {
					.testimonial .container .user .text {
						max-width: 280px;
					}
				}

				.testimonial .container .user .text h5 {
					font-weight: 600;
					font-size: 1.25rem;
					letter-spacing: -0.5px;
					margin-bottom: 4px;
				}

				.testimonial .container .user .text small {
					font-size: 1rem;
					letter-spacing: -0.5px;
				}

				.testimonial .container .user .text.mw-400 {
					max-width: calc(400px * var(--scale));
				}

				@media (max-width: 1199px) {
					.testimonial .container .user .text.mw-400 {
						max-width: 400px;
					}
				}

				.hero {
					position: relative;
					background: rgba(122, 119, 120, 0.31);
					color: #fff;
					padding-top: calc(250px * var(--scale));
					overflow: hidden;
				}

				.hero .container h5 {
					font-weight: 400;
					font-size: 1.25rem;
					letter-spacing: -0.5px;
					line-height: 1.4;
					margin-bottom: 1.25rem;
				}

				.hero .container h1 {
					font-weight: 400;
					letter-spacing: -0.04em;
					line-height: 1.11;
					max-width: calc(1024px * var(--scale));
				}

				@media (min-width: 992px) and(max-width: 1199px) {
					.hero .container h1 {
						font-size: 4.25rem;
					}
				}

				@media (min-width: 768px) and(max-width: 991px) {
					.hero .container h1 {
						font-size: 3.5rem;
					}
				}

				@media (max-width: 767px) {
					.hero .container h1 {
						font-size: 2rem;
					}
				}

				.hero .image {
					margin-top: calc(125px * var(--scale));
				}
				.image img {
					margin-top: calc(125px * var(--scale));
				}

				.hero .image picture img {
					width: 100%;
					max-width: none;
					height: auto;
				}

				.intro {
					padding-top: calc(150px * var(--scale));
					overflow: hidden;
				}

				.intro .container .text-wrapper {
					position: relative;
					padding-left: 33.33%;
				}

				@media (max-width: 767px) {
					.intro .container .text-wrapper {
						padding-left: 0;
						max-width: calc(640px * var(--scale));
						margin: 0 auto;
					}
				}

				.intro .container .text-wrapper p {
					padding-bottom: calc(60px * var(--scale));
				}

				.intro .container .image-grid {
					padding-top: calc(100px * var(--scale));
				}

				.website {
					overflow: hidden;
				}

				.website .container .text-block {
					max-width: calc(820px * var(--scale));
					margin: 0 auto;
					padding-top: calc(160px * var(--scale));
					padding-bottom: calc(160px * var(--scale));
				}
				.text-block {
					padding-top: calc(100px * var(--scale));
					padding-bottom: calc(180px * var(--scale));
					padding-left: 34.3%;
					font-weight: 400;
					font-size: 3rem;
					letter-spacing: -1px;
					line-height: 1.18;
				}
			`}</style>
		</Layout>
	);
}

export const query = graphql`
	query PostBySlug($id: String) {
		post: datoCmsArticle(id: { eq: $id }) {
			title
			slug
			excerpt
			innhold {
				value
			}
			thumbnail {
				url
			}
			images {
				url
			}
		}
	}
`;
